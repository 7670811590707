import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatBottomSheet, MatBottomSheetRef, MatDialog } from '@angular/material';
import { AlertDialogComponent } from 'src/app/shared-component/alert-dialog/alert-dialog.component';
import { HttpsvcService } from 'src/app/sharedservices/httpsvc.service';

@Component({
  selector: 'app-addmerchant',
  templateUrl: './addmerchant.component.html',
  styleUrls: ['./addmerchant.component.scss']
})
export class AddmerchantComponent implements OnInit {

  frmAddCompany!: FormGroup;

  userid: number = 0;
  constructor(private bottomSheet: MatBottomSheet,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private httpsvc: HttpsvcService, 
    private bottomSheetRef: MatBottomSheetRef<AddmerchantComponent>) {
    this.frmAddCompany = fb.group({
      createuserid: [0, Validators.required],
      mastermerchantid: [0, Validators.required],
      merchanttype: ['1', Validators.required],
      merchantwebsite: ['na', Validators.required],
      businessname: ['', Validators.required],
      merchantcode: ['', Validators.required],
    })
   }

  ngOnInit() {
  }
  
  submit(){
 
    
    if(this.frmAddCompany.invalid){
      return;
    }
    this.userid = Number.parseInt(localStorage.getItem("userid") || '0');
    this.frmAddCompany.controls['merchanttype'].setValue('1');
    this.frmAddCompany.controls['mastermerchantid'].setValue(0);
    this.frmAddCompany.controls['createuserid'].setValue(this.userid); 
    this.httpsvc.postData("api/merchant/add", this.frmAddCompany.value).subscribe({
      next: this.handleAddCompanyResponse.bind(this),
      error: this.handleAddCompanyError.bind(this)
    })
  }

  handleAddCompanyResponse(res: any){ 
    if(res.status){
      const dialogRef = this.dialog.open(AlertDialogComponent, {
        data : {
          title: "New Merchant",
          message: res.message
        }
      });
  
      dialogRef.afterClosed().subscribe(result => { 
  
        if(result){
          this.bottomSheetRef.dismiss(result);
        }
  
  
      });
    }
  }
  handleAddCompanyError(err: any){ 
  }
  
  cancel(){
    // this.dialog.open(AlertDialogComponent);
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      data : {
        title: "New Merchant",
        message: "Do you want to cancel this request?"
      }
    });

    dialogRef.afterClosed().subscribe(result => { 

      if(result){
        this.bottomSheetRef.dismiss();
      }


    });
    
  }

}
