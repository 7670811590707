import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CinemaCardsApi {
    // readonly rootUrl = 'http://localhost:62995';
    // readonly rootUrl =  'http://localhost/backendapi';
    // username = '5cbdc56a541de';
    // password = 'tHLgqEJkyFBn1shiwla2271xC4AFwIruw4h7'; 
    
    
     constructor(private http: HttpClient) { }

    // auth() {
    //     var data = "username=" + this.username + "&password=" + this.password + "&grant_type=password";
    //     console.log(data);
    //     var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded' });
    //     return this.http.post(this.rootUrl + 'auth', data, { headers: reqHeader });
    // }

    // checkBalance(params: any) {
    //     return this.http.post(
    //         this.rootUrl + 'api/balance',
    //         params,
    //         { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem('lrttoken') }) }
    //     );
    // }

    // activateCard(params) {
    //     return this.http.post(
    //         this.rootUrl + '/api/activategc',
    //         params,
    //         { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem('lrttoken') }) }
    //     );
    // }

    // cardSale(params) {
    //     return this.http.post(
    //         this.rootUrl + '/api/sale',
    //         params,
    //         { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem('lrttoken') }) }
    //     );
    // }

    // cardTransaction(params) {
    //     return this.http.post(
    //         this.rootUrl + 'api/gchistory',
    //         params,
    //         { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem('lrttoken') }) }
    //     );
    // }
    // getUserTransactions(params) {
    //     return this.http.post(
    //         this.rootUrl + '/api/cashiertrx',
    //         params,
    //         { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem('lrttoken') }) }
    //     );
    // }

    // loadCard(params: any) {
    //     return this.http.post(
    //         this.rootUrl + 'api/balance',
    //         params,
    //         { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem('lrttoken') }) }
    //     );
    // }

}