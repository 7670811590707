import { Component, OnInit } from '@angular/core';
import { SystemUserService } from '../sharedservices/system-user-services';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, ValidatorFn, ValidationErrors } from '@angular/forms';
import { Md5 } from 'md5-typescript';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material';
 
export const passwordMatchValidator: ValidatorFn = (formGroup: FormGroup): ValidationErrors | null => {
  if (formGroup.get('newpass').value === formGroup.get('confirmpass').value)
    return null;
  else
    return {passwordMismatch: true};
};

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  name : string = '';
  status : string = '';
  bu: string = '';
  username ; string = '';
  access : string = '';
  changepass : boolean = false;
  minPw = 8;

  changepassForm: FormGroup;

  
  constructor(public fb: FormBuilder,
    private route : Router,
    private spinner: NgxSpinnerService,
    private systemusersvc : SystemUserService,
    private _snackBar: MatSnackBar
    
     ) {
      // this.changepassForm = new FormGroup({
      //   'currentpass' : new FormControl(),
      //   'newpass': new FormControl(),
      //   'confirmpass': new FormControl() 
      // })

      this.changepassForm = this.fb.group({
        currentpass:['', [Validators.required]],
        newpass: ['', [Validators.required, Validators.minLength(this.minPw)]],
        confirmpass: ['', [Validators.required]]
        }, {validator: passwordMatchValidator});
     }

  ngOnInit() {
    this.getProfile();
    this.changepass = false;
  }

  /* Shorthands for form controls (used from within template) */
  get currentpass() { return this.changepassForm.get('currentpass'); }
  get newpass() { return this.changepassForm.get('newpass'); }
  get confirmpass() { return this.changepassForm.get('confirmpass'); }

  /* Called on each input in either password field */
  onPasswordInput() {
    if (this.changepassForm.hasError('passwordMismatch'))
      this.confirmpass.setErrors([{'passwordMismatch': true}]);
    else
      this.confirmpass.setErrors(null);
  }
  
  getProfile() {
    this.spinner.show();

    this.systemusersvc.getprofile().subscribe((data: any) => { 
      this.name = data.name;
      this.access = data.usertypename;
      this.username = data.username;
      this.bu = data.StoreCode;
      this.status = data.status;
      this.spinner.hide();
    })
  }

  changepassword(){
    this.changepass = true;

  }
  cancelchangepassword(){
    this.changepass = false;
  }

  changepwd(){
     let params = {
      "currentpass": Md5.init(this.currentpass.value),
      "newpass" : Md5.init(this.newpass.value),
      "userid" : localStorage.getItem("userid")
    }; 
    this.systemusersvc.changePassword(params).subscribe((data:any) => { 
      this.openSnackBar("Password Updated.", "");
      this.currentpass.setValue("");
      this.newpass.setValue("");
      this.confirmpass.setValue("");
      this.changepass = false;
    },
    (err:HttpErrorResponse) =>{
      if(err.status == 403){
        this.openSnackBar("Current password does not match.", "Try again...");    
      }else if(err.status == 404){
        this.openSnackBar("User not found.", "Try again...");
      }else if(err.status == 304){
        this.openSnackBar("Update Failed.", "Try again...");
      }
    
    })
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
      verticalPosition: 'top',
      horizontalPosition: 'right'
    });
  }

}
