import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { CardTransactionApi } from 'src/app/sharedservices/card-transaction-api';
import { AllAlertDialogComponent } from 'src/app/allpay-wallet/alert-dialog/allalert-dialog.component';
import { MatDialog } from '@angular/material';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-avg-sale',
  templateUrl: './avg-sale.component.html',
  styleUrls: ['./avg-sale.component.scss']
})
export class AvgSaleComponent implements OnInit {

  
  txnDate: any;
  saleForm: FormGroup;  
  constructor(public cardsapi: CardTransactionApi,
              public dialog: MatDialog,
              private route : Router,
              private spinner: NgxSpinnerService,
              private datePipe: DatePipe
      ) { 
        this.txnDate = new Date();
    this.saleForm = new FormGroup({
      'cardnumber' : new FormControl(),
      'amount' : new FormControl(),
       
    })
  }

  ngOnInit() {
  }
  doPayment(){
    this.spinner.show();
    let formvalue = this.saleForm.value;  
    let possible = "1234567890";
    const lengthOfCode = 10;
    let merchantrefno = this.makeRandom(lengthOfCode, possible);
  
  
    let params = {
      "merchantrefno":merchantrefno,
      "trxtype":"SALE",
      "trxdate": this.datePipe.transform(this.txnDate, 'yyyy-MM-dd HH:mm:ss'),
      "cardnumber": formvalue.cardnumber,
      "trxamount":  formvalue.amount,
      "deviceid":"000000",
      "userid": Number.parseInt(localStorage.getItem('userid')),
      "storecode":localStorage.getItem('storecode'),
      "reqhash":""
    }; 
    console.log(params)
    this.cardsapi.cardSale(params).subscribe((data:any) => { 
      this.spinner.hide();
      var cno;
      if(data.resp.code == '0')
      {
        cno = formvalue.cardnumber;    
        this.openAlertDialog(data.resp.message , data.resp.data.cardnumber, data.resp.data.balance,"", data.resp.referenceno , true);
      }else{
        this.openAlertDialog(data.resp.message,"", "","","" , false);
        
      }
    },
    (err : HttpErrorResponse)=>{ 
      this.spinner.hide();  
       this.route.navigate(['index']);
      
   })

  
  }
  
  makeRandom(lengthOfCode: number, possible: string) {
    let text = "";
    for (let i = 0; i < lengthOfCode; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
      return text;
  }

  keyIn(value: any){
    // this.saleForm.controls['cardnumber'].setValue('');
    //  this.saleForm.controls['cardnumber'].setValue(value);
     
  }

  openAlertDialog(message: string, cardnumber: string, balance:string, details:string, refno: string, pwithdetails: boolean) {
    // let waletFormData = this.walletForm.getRawValue();
    const dialogRef = this.dialog.open(AllAlertDialogComponent, {
      width: '450px',
      data: {
        details:{
          refno: refno
        },
        message: message,
        cardnumber: cardnumber,
        balance: balance, 
        withgcdetails: pwithdetails,
        buttonText: {
          ok: 'OK'
        }
      }
    });
    // const snack = this.snackBar.open('Snack bar open before dialog');

    dialogRef.afterClosed().subscribe((confirmed: boolean) => { 
      if (confirmed) {
        this.saleForm.setValue({
          "cardnumber":"",
          "amount":""  
        })
        // this.openPrintARDialog();
      }
      this.spinner.hide();  
      //
    });
  }
}
 