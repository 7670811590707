import { Component, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheet, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { HttpsvcService } from 'src/app/sharedservices/httpsvc.service';
import { NewusersComponent } from '../newusers/newusers.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  @ViewChild('userSort' , { static: false }) userSort = new MatSort();
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  dataSource = new MatTableDataSource();
  displayedColumns: string[] = ["id","fname", "lname", "username","usertype", "status", "action" ];
  companies: any = []
  selectedcompany: string = "";

  brands: any = []
  selectedbrand: string = "";

  branches: any = []
  selectedbranch: string = "";

  users: any = []

  constructor(private httpsvc: HttpsvcService,
    private bottomSheet: MatBottomSheet,
    private router: Router) { }

  ngOnInit() {
    this.getMasterMerchants()
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.userSort.disableClear = true;
    this.dataSource.sort = this.userSort;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getMasterMerchants(){
     this.httpsvc.getData('api/merchant/mastermerchants').subscribe({
      next: this.handleGetMasterMerchantResponse.bind(this),
      error: this.handleGetMasterMerchantError.bind(this)
     })
  }

  handleGetMasterMerchantResponse(res: any){ 
    this.companies = res;
    

  }
  handleGetMasterMerchantError(err: any){ 
    
  }

  
  getBrands(){ 
    this.httpsvc.getData('api/merchant/brands/' + this.selectedcompany).subscribe({
      next: this.handleGetBrandsResponse.bind(this),
      error: this.handleGetBrandsError.bind(this)
    })
  }

  handleGetBrandsResponse(res: any){
    this.brands = res;
  }
  handleGetBrandsError(err: any){ 
  }


  getBranches(){ 
    this.httpsvc.getData('api/merchant/branches/' + this.selectedbrand).subscribe({
      next: this.handleGetBranchesResponse.bind(this),
      error: this.handleGetBranchesError.bind(this)
    })
  }

  handleGetBranchesResponse(res: any){
    this.branches = res;
  }
  handleGetBranchesError(err: any){ 
  }

  getUsers(){ 
    this.httpsvc.getData('api/merchant/merchantusers/' + this.selectedbranch).subscribe({
      next: this.handleGetUsersResponse.bind(this),
      error: this.handleGetUsersError.bind(this)
    })
  }

  handleGetUsersResponse(res: any){
    this.users = res;
    this.dataSource = new MatTableDataSource<any>(this.users);
    this.dataSource.sort = this.userSort;
    this.dataSource.paginator = this.paginator;
  }
  handleGetUsersError(err: any){ 
  }

  addUser(){
    this.bottomSheet.open(NewusersComponent); 
  }

  setStatus(userid: any, status: any) {
    let params = {
      status: status,
      userid: userid
    }
    this.httpsvc.patchData('api/merchant/userstatus', params).subscribe({
      next: this.handleSetStatus.bind(this),
      error: this.handleSetStatusError.bind(this)
    })
  }

  handleSetStatus(res: any){ 
    this.getUsers();
  }

  handleSetStatusError(err: any){ 
    this.getUsers();
  }
}
