import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { CardTransactionApi } from 'src/app/sharedservices/card-transaction-api';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { AllAlertDialogComponent } from 'src/app/allpay-wallet/alert-dialog/allalert-dialog.component';
import { HttpErrorResponse } from '@angular/common/http';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-avg-checkbalance',
  templateUrl: './avg-checkbalance.component.html',
  styleUrls: ['./avg-checkbalance.component.scss']
})
export class AvgCheckbalanceComponent implements OnInit {

  cbal: string;
  cno : string; 

  txnDate: any;
  balanceForm: FormGroup;  
  constructor(public fb: FormBuilder,
              public cardsapi: CardTransactionApi,
              public dialog: MatDialog,
              private route : Router,
              private spinner: NgxSpinnerService,
              private datePipe: DatePipe) {
    this.balanceForm = fb.group({
      'cardnumber' : new FormControl()
    }) 
    // this.balanceForm = new FormGroup({
    //   'cardnumber' : new FormControl() 
    // })
  }

  ngOnInit() {
    this.cno = 'xxxxxxxxxx';
    this.cbal = '0.00';
    this.txnDate = new Date();
  }

  makeRandom(lengthOfCode: number, possible: string) {
    let text = "";
    for (let i = 0; i < lengthOfCode; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
      return text;
  }
  
  checkBalance(){
    this.spinner.show();
    let formvalue = this.balanceForm.value; 
    
    if(formvalue.cardnumber == ""){
      this.openAlertDialog('Card number is required.','','', false);
      this.spinner.hide();
      return;
    }
    
    let possible = "1234567890";
    const lengthOfCode = 10;

    let merchantrefno = this.makeRandom(lengthOfCode, possible);
  
  
    let params = {
      "merchantrefno":merchantrefno,
      "trxtype":"BALANCE",
      "trxdate": this.datePipe.transform(this.txnDate, 'yyyy-MM-dd HH:mm:ss'),
      "trxamount":"0",
      "deviceid":"000000",
      "userid": Number.parseInt(localStorage.getItem('userid')),
      "storecode":localStorage.getItem('storecode'),
      "reqhash":"",
      "cardnumber": formvalue.cardnumber
    };
 
 
  this.cardsapi.checkBalance(params).subscribe({
    next:  this.handleCheckBalanceResponse.bind(this),
    error: this.handleCheckBalanceError.bind(this)
  }
  )



  }

  handleCheckBalanceResponse(res: any){
    console.log(res);
    this.spinner.hide(); 
    if (res.resp.code == "0") {
      this.cbal = res.resp.data.balance;
      this.cno = res.resp.data.cardnumber; 
      this.openAlertDialog( 'Gift Card ' + this.cno + ' found.', this.cbal, 'Card Number : ' + this.cno,true);
      
    }else{
      this.openAlertDialog(res.resp.message,"", "",false);
    
    }
    
       
  }

  handleCheckBalanceError(err: any){
    console.log(err)
    this.spinner.hide();
    this.openAlertDialog(err.error,"","",false) 
  }

  onKeydown(event) {
    if (event.key === "Enter") { 
      this.checkBalance();
    }
  }

  openAlertDialog(message: string, balance: string, cardnumber: string, pwithdetails: boolean) {
    // let waletFormData = this.walletForm.getRawValue();
    const dialogRef = this.dialog.open(AllAlertDialogComponent, {
      width: '450px',
      data: {
        details:{
          refno:""
        },
        withgcdetails: pwithdetails,
        message: message,
        cardnumber : cardnumber,
        balance : balance,
        buttonText: {
          ok: 'OK'
        }
      }
    });
    

    dialogRef.afterClosed().subscribe((confirmed: boolean) => { 
      if (confirmed) {
        this.balanceForm.setValue({
          "cardnumber":"" 
        }) 
      }
      this.spinner.hide();  
 
    });
  }
}
