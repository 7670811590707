import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CardTransactionApi {
    readonly rootUrl = environment.baseUrl; 
    private userClaims = new BehaviorSubject<any>({});
    currentUserClaims = this.userClaims.asObservable();

    constructor(private http: HttpClient) { }


    checkBalance(params: any) {
        return this.http.post(
            this.rootUrl + 'giftcard/balance',
            params,
            { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem('lrttoken') }) }
        );
    }

    activateCard(params) {
        return this.http.post(
            this.rootUrl + 'giftcard/activate',
            params,
            { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem('lrttoken') }) }
        );
    }

    cardSale(params) {
        return this.http.post(
            this.rootUrl + 'giftcard/sale',
            params,
            { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem('lrttoken') }) }
        );
    }

    cardTransaction(params) {
        return this.http.post(
            this.rootUrl + 'giftcard/gchistory',
            params,
            { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem('lrttoken') }) }
        );
    }
    getUserTransactions(params) {
        return this.http.post(
            this.rootUrl + 'giftcard/cashiertrx',
            params,
            { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem('lrttoken') }) }
        );
    }


}