import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'; 
import { SystemUserService } from '../sharedservices/system-user-services';
import { FormGroup, FormControl } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { AlertDialogComponent } from '../shared-component/alert-dialog/alert-dialog.component';
import { Md5 } from "md5-typescript";
import { MatSnackBar } from '@angular/material';
// import { SystemUser } from "../sharedservices/user-interface";
import jwt_decode from 'jwt-decode';


@Component({
  selector: 'app-landing',
    templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {


  // businessunits = [
  //   {
  //     id: 1,
  //     title: 'ALLPAY WALLET',
  //     img: '',
  //     link: '/allpay/main'
      
  //   },
    // {
    //   id: 2,
    //   title: 'GETS',
    //   img: 'assets/gets.png',
    //   link: '/gets'
    // },
    // {
    //   id: 3,
    //   title: 'ALLVALUE',
    //   img: 'assets/allvalue.jpg',
    //   link: '/allvalue/avgmain'
    // },
    // {
    //   id: 4,
    //   title: 'VISTA CINEMA',
    //   img: 'assets/vistacinema.png',
    //   link: '/vistacinema'
    // } 
  // ];

  // lrttotken: string;
  // authorized: string;
  // storecode: string;

  username : string;
  password : string;
  

  constructor(public dialog: MatDialog, 
    private route : Router,
    private spinner: NgxSpinnerService,
    private systemusersvc : SystemUserService,
    private _snackBar: MatSnackBar
    // private systemuser : SystemUser
    ) {}

  openDialog(): void {
    const dialogRef = this.dialog.open(SignInDialog, {
      disableClose: true,
      autoFocus: true,
      width: '450px', 
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.spinner.show();
      this.username = result.username;
      this.password = result.password;
       
      if(this.username === undefined || this.username == null || this.username == "")
      {
        this.openDialog();    
      }
      if(this.password === undefined || this.password == null || this.password == "")
      {
        this.openDialog();    
      }
      
      this.systemusersvc.Authenticate(this.username,this.password,"").subscribe((data:any) => {
      // this.systemusersvc.Authenticate(this.username,Md5.init(this.password),"").subscribe((data:any) => {
      
      
      let token = this.getDecodedAccessToken(data.token)
      console.log(token)
      localStorage.setItem('lrttoken', data.token); 
      // this.getClaims();
      // localStorage.setItem('lrttoken', data.access_token);
      localStorage.setItem('authorized', 'true');
      localStorage.setItem('storecode',token.StoreCode);
      localStorage.setItem('userid', token.UserId);
      localStorage.setItem('access', token.access);
      localStorage.setItem('branch', token.branch);
      localStorage.setItem('mid', token.merchantid);
      localStorage.setItem('utype', token.type);
      localStorage.setItem('usertypename', token.Role);
      localStorage.setItem('username', token.Username);
      
      
      
      this.route.navigate(['dashboard']);
      this.spinner.hide();
      dialogRef.close();
    },
     (err : HttpErrorResponse)=>{
       console.log(err);
       this.spinner.hide();
       this.openSnackBar("Authorization Failed. " + err.error.message)
       this.openDialog();
        
    });

    });
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, "Try Again...", {
      duration: 5000,
      verticalPosition: 'top'
    });
  }

  ngOnInit() {
    this.spinner.hide();
    localStorage.clear();
      
    this.openDialog();
  }

  getClaims(): void {
    this.systemusersvc.getUserClaims().subscribe((data:any) => { 
      this.route.navigate(['dashboard']); 
    })
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch(Error) {
      return null;
    }
  }  
 }

 
@Component({
  selector: 'signin-dialog',
  templateUrl: 'signin-dialog.html',
})
export class SignInDialog {

  SignInForm : FormGroup;

  constructor(
    private route : Router,
    private spinner: NgxSpinnerService,
    private systemusersvc : SystemUserService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<SignInDialog>,
    public alertRef: MatDialogRef<AlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

      this.SignInForm = new FormGroup({
        'username' : new FormControl(),
        'password' : new FormControl()
      })
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onOkClick(): void {
    this.dialogRef.close();
  }

  signIn(): void {
    this.spinner.show(); 
    let username = this.SignInForm.controls['username'].value;
    let password = this.SignInForm.controls['password'].value;  
  }

  openAlert(): void {
    const alertRef =  this.dialog.open(AlertDialogComponent , {
      width : '300',
      height : '100'
    });

    alertRef.afterClosed().subscribe(result => {
    });
  }

}