import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core'; 
import { HttpClientModule } from '@angular/common/http';
import { NgMaterialMultilevelMenuModule } from 'ng-material-multilevel-menu'; 
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LandingComponent, SignInDialog } from './landing/landing.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list'; 
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog'
import { MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatDatepickerModule  } from "@angular/material/datepicker"; 

import { MatSnackBarModule,  } from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";

import { MatChipsModule } from '@angular/material/chips';

import { SystemUserService } from './sharedservices/system-user-services';  
import { AllvalueMainComponent } from './landing-dashboard/allvalue/allvalue-main/allvalue-main.component'; 
import { AuthguardGuard } from './authguard.guard';
import { AlertDialogComponent } from './shared-component/alert-dialog/alert-dialog.component';
import { AllvalueDashboardComponent } from './landing-dashboard/allvalue/allvalue-dashboard/allvalue-dashboard.component';
import { AvgSaleComponent } from './landing-dashboard/allvalue/avg-sale/avg-sale.component';
import { AvgActivateComponent } from './landing-dashboard/allvalue/avg-activate/avg-activate.component';
import { AvgCheckbalanceComponent } from './landing-dashboard/allvalue/avg-checkbalance/avg-checkbalance.component';
import { AvgGctransactionComponent } from './landing-dashboard/allvalue/avg-gctransaction/avg-gctransaction.component';
import { AvgTransactionComponent } from './landing-dashboard/allvalue/avg-transaction/avg-transaction.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { CardTransactionApi } from './sharedservices/card-transaction-api';
import { AllpayWalletComponent } from './allpay-wallet/allpay-wallet.component';
import { WalletLoadingApi } from './sharedservices/wallet-api';
import { ConfirmDialogComponent } from './allpay-wallet/confirm-dialog/confirm-dialog.component';
import { AllpayWalletMainComponent } from './allpay-wallet/allpay-wallet-main/allpay-wallet-main.component';
import { AllpayWalletDashboardComponent } from './allpay-wallet/allpay-wallet-main/allpay-wallet-dashboard/allpay-wallet-dashboard.component';
import { LandingDashboardComponent } from './landing-dashboard/landing-dashboard.component';
import { AllAlertDialogComponent } from './allpay-wallet/alert-dialog/allalert-dialog.component';
import { PrintarDialogComponent } from './allpay-wallet/printar-dialog/printar-dialog.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AllvalueComponent } from './landing-dashboard/allvalue/allvalue.component';
import { RequestforrefundComponent } from './landing-dashboard/allvalue/requestforrefund/requestforrefund.component';
 import { RptgiftcardtxnComponent } from './landing-dashboard/allvalue/avg-transaction/rptgiftcardtxn/rptgiftcardtxn.component';
import { RptwalletloadingComponent } from './landing-dashboard/allvalue/avg-transaction/rptwalletloading/rptwalletloading.component';
import { DatePipe } from '@angular/common'; 
import { CinemaCardsApi } from './sharedservices/cinema-cardsapi-service';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { Md5 } from 'md5-typescript';
import { UsersComponent } from './landing-dashboard/admins/users/users.component'; 
import { MatSelectModule } from '@angular/material/select';
import { NewusersComponent } from './landing-dashboard/admins/newusers/newusers.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { AdminpageComponent } from './landing-dashboard/admins/adminpage/adminpage.component';
import { CompanyComponent } from './landing-dashboard/admins/merchants/company/company.component';
import { BrandsComponent } from './landing-dashboard/admins/merchants/brands/brands.component';
import { BranchesComponent } from './landing-dashboard/admins/merchants/branches/branches.component';
import { AddmerchantComponent } from './landing-dashboard/admins/merchants/addmerchant/addmerchant.component';
import { AddbrandComponent } from './landing-dashboard/admins/merchants/addbrand/addbrand.component';
import { AddbranchComponent } from './landing-dashboard/admins/merchants/addbranch/addbranch.component';
import { SearchComponent } from './landing-dashboard/allvalue/search/search.component'; 
import { MatNativeDateModule } from '@angular/material/core';
 
 


@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    SignInDialog, 
    AllvalueComponent, 
    AllvalueMainComponent,
    AlertDialogComponent,
    AllvalueDashboardComponent,
    AvgSaleComponent,
    AvgActivateComponent,
    AvgCheckbalanceComponent,
    AvgGctransactionComponent,
    AvgTransactionComponent,
    AllpayWalletComponent,
    ConfirmDialogComponent,
    AllpayWalletMainComponent,
    AllpayWalletDashboardComponent,
    LandingDashboardComponent ,
    AllAlertDialogComponent,
    PrintarDialogComponent,
    RequestforrefundComponent,
    RptwalletloadingComponent,
    RptgiftcardtxnComponent, 
    UserProfileComponent,
     UsersComponent, 
     NewusersComponent, 
     AdminpageComponent, 
     CompanyComponent, 
     BrandsComponent, 
     BranchesComponent, 
     AddmerchantComponent, AddbrandComponent, AddbranchComponent, SearchComponent,
     

  ],
  imports: [
    BrowserModule, 
    HttpClientModule,
    AppRoutingModule, 
    NgMaterialMultilevelMenuModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    NgxSpinnerModule,
    MatToolbarModule,
    MatBottomSheetModule,
    MatSidenavModule,
    MatButtonModule,
    MatSnackBarModule,
    MatIconModule, 
    MatListModule, 
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatGridListModule,
    MatMenuModule,
    MatDialogModule,
    MatDialogModule,
    MatButtonToggleModule,
    MatTableModule,
    MatPaginatorModule,
    MatSnackBarModule,
    MatSortModule, 
    MatSelectModule,
    MatChipsModule,
    MatDatepickerModule,
    MatNativeDateModule,      
  ],
  
  entryComponents:[SignInDialog, 
    AlertDialogComponent,
    ConfirmDialogComponent, 
    AllAlertDialogComponent,
    PrintarDialogComponent,
    SearchComponent ],
   providers: [
    Md5,
    DatePipe,
    AuthguardGuard,
    SystemUserService,
    CardTransactionApi,
    WalletLoadingApi, 
    CinemaCardsApi,
    {provide: MAT_DIALOG_DEFAULT_OPTIONS,  useValue: {hasBackdrop: true}} , 
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
