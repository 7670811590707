import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-allalert-dialog',
  templateUrl: './allalert-dialog.component.html', 
  styleUrls: ['./allalert-dialog.component.scss']
})
export class AllAlertDialogComponent implements OnInit {

  details: string = "";
  message: string = ""
  refno: string = ""
  subscriber: string = ""
  cashinamount: string = "0.00"
  balance: string = ""
  cardnumber: string = ""
  confirmButtonText = "OK"
  withdetails: boolean = false
  withgcdetails: boolean = false
  
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<AllAlertDialogComponent>) {
      if(data){
        this.message = data.message || this.message;
        this.balance = data.balance || this.balance;
        this.cardnumber = data.cardnumber || this.cardnumber;
        // this.details = data.details || this.details;
        this.refno =   data.details.refno  || this.refno;
        this.subscriber = data.details.subscriber || this.subscriber
        this.cashinamount = data.details.amount || this.cashinamount
        if (data.buttonText) {
          this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
          
        } 
        if(data.withdetails){
          this.withdetails = true
          this.refno =   data.details.refno  || this.refno;
        }
        if(data.withgcdetails){
          this.withgcdetails = true
          // this.refno =   data.details.refno  || this.refno;
        }
      }
  }

  ngOnInit() {
  }

  onConfirmClick(): void {
    this.dialogRef.close(true);
  }

}
