import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { WalletLoadingApi } from 'src/app/sharedservices/wallet-api';
import { MatDialog, MatSnackBar, MatTableDataSource, MatPaginator, MatBottomSheet } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { Guid } from 'guid-typescript';
import { HttpErrorResponse } from '@angular/common/http';
import { DatePipe, formatDate } from '@angular/common';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { SearchComponent } from '../../search/search.component';

@Component({
  selector: 'app-rptwalletloading',
  templateUrl: './rptwalletloading.component.html',
  styleUrls: ['./rptwalletloading.component.scss']
})
export class RptwalletloadingComponent implements OnInit {
 
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
 
  isValid: boolean = false;
  displayedColumns: string[] = ["transactiondate","referenceno", "status", "amount"];
  dataSource = new MatTableDataSource();

  public now: Date = new Date();
  walletForm: FormGroup;  
  durationInSeconds = 5; 
  continue: boolean = false;
  guid : Guid;
  sum: number;
  gdata: any;
  txnDate = new Date();
  txdate:any;
  txtdatefrom: any =  new Date();
  txtdateto: any =  new Date();
  

  constructor(private route : Router,
    private bottomSheet: MatBottomSheet,
    public fb: FormBuilder,
    public walletapi: WalletLoadingApi,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private spinner: NgxSpinnerService,
    private datePipe: DatePipe
    ) { 
          }

  ngOnInit() {
    this.getLoadTransaction(); 
     
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  getLoadTransaction() {
    this.spinner.show();
    let params = {
      'userid' : localStorage.getItem('userid'),
      'TxnDateFrom' : this.datePipe.transform(this.txtdatefrom, 'yyyy-MM-dd'),
      'TxnDateTo' : this.datePipe.transform(this.txtdateto, 'yyyy-MM-dd')
      
    } 
    this.walletapi.getLoadingTransactions(params).subscribe((data: any) => { 
      this.gdata = data;
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.spinner.hide();
      this.getTotalLoad();
      
    },
    (err : HttpErrorResponse)=>{ 
      this.spinner.hide();  
      //  this.route.navigate(['index']);
   }
    )

    
  }
  getTotalLoad() {
    
    // this.gdata.forEach(d => {
    //   this.sum = this.sum + d.amount;
    //   console.log(this.sum);
    // });
    this.sum = this.gdata.reduce(function (sum, amount) {
      return sum + Number.parseFloat(amount.amount);
    }, 0);
     
    }
    donloadReport() {
      this.exportCSV();
    }
    //Download CSV Report
  exportCSV() {
    var csvData = this.ConvertToCSV(this.gdata);
    var a = document.createElement("a");
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    var blob = new Blob([csvData], { type: 'text/csv' });
    var url = window.URL.createObjectURL(blob);
    a.href = url;
    // a.download = 'SalesReport_'+ this.datePipe.transform(this.startDate,"yyMMxdd") + "-" + this.datePipe.transform(this.endDate, "yyMMdd") +'.csv';
    a.download = 'CollectionReport.csv';
    
    a.click();
  }
  
  ConvertToCSV(objArray) {
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var str = '';
    var row = "";
  
    for (var index in objArray[0]) {
      //Now convert each value to string and comma-separated
      row += index + ',';
    }
    row = row.slice(0, -1);
    //append Label row with line break
    str += row + '\r\n';
  
    for (var i = 0; i < array.length; i++) {
      var line = '';
      for (var index in array[i]) {
        if (line != '') line += ','
  
        line += array[i][index];
      }
      str += line + '\r\n';
    }
    return str;
  }
   

  dateFromChangeHandler(event: any){ 
    this.txtdatefrom = event 
  }

  dateToChangeHandler(event: any){
    this.txtdateto = event 
  }

  openBottomSheet(): void {
    const bottomSheetRef = this.bottomSheet.open(SearchComponent);
    bottomSheetRef.afterDismissed().subscribe((data: any) => { 
  
      this.txtdateto =  data.end
      this.txtdatefrom = data.start 
      this.getLoadTransaction()
    });
  }
}
