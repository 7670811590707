import { Component, OnInit } from '@angular/core';
import { CardTransactionApi } from 'src/app/sharedservices/card-transaction-api';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { WalletLoadingApi } from 'src/app/sharedservices/wallet-api';
import { MatDialog } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-avg-transaction',
  templateUrl: './avg-transaction.component.html',
  styleUrls: ['./avg-transaction.component.scss']
})
export class AvgTransactionComponent implements OnInit {

  txns : any;
  constructor( public cardsapi: CardTransactionApi,
    private route : Router,
    public fb: FormBuilder,
    public walletapi: WalletLoadingApi,
    public dialog: MatDialog, 
    private spinner: NgxSpinnerService) { }

  ngOnInit() { 
  }

  getTransactions(){
    this.spinner.show(); 
    let possible = "1234567890";
    const lengthOfCode = 10; 
  
  
    let params = { 
      "trxdate": new Date(), 
      "userid": localStorage.getItem('userid'),
      "storecode":localStorage.getItem('storecode'),
      "reqhash":""
    }; 
    
    this.cardsapi.getUserTransactions(params).subscribe((data:any) => {  
      this.txns = data;
    },
    (err : HttpErrorResponse)=>{ 
      this.spinner.hide();  
       this.route.navigate(['index']);
      
   })

  
  }


  donloadReport() {
    this.exportCSV();
  }
  //Download CSV Report
exportCSV() {
  var csvData = this.ConvertToCSV(this.txns);
  var a = document.createElement("a");
  a.setAttribute('style', 'display:none;');
  document.body.appendChild(a);
  var blob = new Blob([csvData], { type: 'text/csv' });
  var url = window.URL.createObjectURL(blob);
  a.href = url;
  // a.download = 'SalesReport_'+ this.datePipe.transform(this.startDate,"yyMMxdd") + "-" + this.datePipe.transform(this.endDate, "yyMMdd") +'.csv';
  a.download = 'CollectionReport.csv';
  
  a.click();
}

ConvertToCSV(objArray) {
  var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
  var str = '';
  var row = "";

  for (var index in objArray[0]) {
    //Now convert each value to string and comma-separated
    row += index + ',';
  }
  row = row.slice(0, -1);
  //append Label row with line break
  str += row + '\r\n';

  for (var i = 0; i < array.length; i++) {
    var line = '';
    for (var index in array[i]) {
      if (line != '') line += ','

      line += array[i][index];
    }
    str += line + '\r\n';
  }
  return str;
}

}
