import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { CardTransactionApi } from 'src/app/sharedservices/card-transaction-api';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router'; 
import { AllAlertDialogComponent } from 'src/app/allpay-wallet/alert-dialog/allalert-dialog.component';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-avg-activate',
  templateUrl: './avg-activate.component.html',
  styleUrls: ['./avg-activate.component.scss']
})
export class AvgActivateComponent implements OnInit {

    cbal :any;
    cno: any;
 
    activateForm: FormGroup;  
    constructor(public fb: FormBuilder,
                public cardsapi: CardTransactionApi,
                public dialog: MatDialog,
                private route : Router,
                private spinner: NgxSpinnerService) { 
      this.activateForm = new FormGroup({
        'cardnumber' : new FormControl(),
        'siorno': new FormControl() 
      })

      this.activateForm = fb.group({
        'cardnumber' : ['',Validators.required],
        'siorno': ['',Validators.required] 
      })
    }

  ngOnInit() {
  }

  makeRandom(lengthOfCode: number, possible: string) {
    let text = "";
    for (let i = 0; i < lengthOfCode; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
      return text;
  }

  doActivate(){
    this.spinner.show(); 
    let formvalue = this.activateForm.value; 
    if(formvalue.cardnumber == ""){
      this.openAlertDialog('Card number is required.','','', false);
      this.spinner.hide();
      return;
    }
    if(formvalue.siorno == ""){
      this.openAlertDialog('Sales invoice / OR number is required.','','', false);
      this.spinner.hide();
      return;
    }
    let possible = "1234567890";
    const lengthOfCode = 10;
    let merchantrefno = this.makeRandom(lengthOfCode, possible);
  
  
    let params = {
      "merchantrefno":merchantrefno,
      "trxtype": "ACTIVATE",
      "trxdate": new Date(),
      "cardnumber": formvalue.cardnumber,
      "trxamount": "0",
      "deviceid": "000000",
      "userid": Number.parseInt(localStorage.getItem('userid')),
      "storecode":localStorage.getItem('storecode'),
      "reqhash": "",
      "siorno":formvalue.siorno
    };
    
 

  this.cardsapi.activateCard(params).subscribe({
    next: this.handleActivateCardResponse.bind(this),
    error: this.handleActivateCardError.bind(this)
  })

  
  }

  handleActivateCardResponse(res: any){ 
    this.spinner.hide();  
    if(res.resp.code > 0){
      this.openAlertDialog(res.resp.message, "", "", false );
    }else{
      this.openAlertDialog(res.resp.message + "Gift Card Activated", "", "", false );
    }
  }

  handleActivateCardError(err: any){ 
    this.spinner.hide();  
    
  }
  

  openAlertDialog(message: string, cardno: string, cardbal: string, pwithdetails: boolean) {
    // let waletFormData = this.walletForm.getRawValue();
    const dialogRef = this.dialog.open(AllAlertDialogComponent, {
      width: '450px',
      data: {
        withdetails: pwithdetails,
        details:{
          refno:""
        },
        cardnumber: 'Card Number : ' + cardno,
        balance: 'Balance : ' + cardbal,
        message: message,
        buttonText: {
          ok: 'OK'
        }
      }
    });
    
    dialogRef.afterClosed().subscribe((confirmed: boolean) => { 
      if (confirmed) {
        this.activateForm.setValue({
          "cardnumber":"" ,
          "siorno": ""
        }) 
      }
      this.spinner.hide();  
      //
    });
  }
  onKeydown(event) {
    if (event.key === "Enter") { 
      this.doActivate();
    }
  }
  keyIn() { 
  }
}
