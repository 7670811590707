import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'; 
import { Observable, BehaviorSubject } from 'rxjs';  
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
  })
  export class SystemUserService {
    readonly baseUrl = environment.baseUrl;  
    private userClaims = new BehaviorSubject<any>({});
    currentUserClaims = this.userClaims.asObservable();
  
    constructor(private http: HttpClient) { }
    
  
    changeUserClaims(claims){
      this.userClaims.next(claims);
    }
  
    getprofile(){
      return this.http.get(
        this.baseUrl + 'user/claims',
        { headers: new HttpHeaders({'Authorization':'Bearer ' + localStorage.getItem('lrttoken')}) }
      );
    }
  
    Authenticate(userName, password, storecode) {
      let params = {
        username: userName,
        password: password
      } 
      return this.http.post(this.baseUrl + 'user',params)
    }

    getUserClaims() { 
      return this.http.get(
        this.baseUrl + 'user/claims',
         { headers: new HttpHeaders({'Authorization':'Bearer ' + localStorage.getItem('lrttoken')}) }
        );
    }
  
    getSystemUsers() {
      return this.http.post(
        this.baseUrl + '/api/systemusers/users',null,
        { headers: new HttpHeaders({'Authorization':'Bearer ' + localStorage.getItem('lrttoken')}) }
      );
    }
    changePassword(params) {
      return this.http.post(
        this.baseUrl + 'api/changepass',
        params,
        { headers: new HttpHeaders({'Authorization':'Bearer ' + localStorage.getItem('lrttoken')}) }
      );
    }
  
    changeUserSecDetails(params) {
      return this.http.post(
        this.baseUrl + '/api/systemusers/updatesecdetails',
        params,
        { headers: new HttpHeaders({'Authorization':'Bearer ' + localStorage.getItem('userToken')}) }
      );
    }
  }