import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-printar-dialog',
  templateUrl: './printar-dialog.component.html',
  styleUrls: ['./printar-dialog.component.scss']
})
export class PrintarDialogComponent implements OnInit {

  message: ''
  trxdate: ''
  trxrefno: ''
  loadrefno: ''
  trxstatus: ''
  store:''
  confirmButtonText = "Print"
  cancelButtonText = "Cancel"
  
  constructor(@Inject(MAT_DIALOG_DATA) private data: any,
              private dialogRef: MatDialogRef<PrintarDialogComponent>) {
                if(data){
                  this.message = data.message || this.message;
                  this.trxdate= data.trxdate  || this.trxdate;
                  this.trxrefno= data.trxrefno || this.trxrefno;
                  this.loadrefno= data.loadrefno || this.loadrefno;
                  this.trxstatus= data.trxstatus || this.trxstatus;
                  this.store= data.store || this.store;
          
                  if (data.buttonText) {
                    this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
                    this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
                  }
                }
               }

  ngOnInit() {
  }

  onConfirmClick(): void {
    this.dialogRef.close(true);
  }

  onCancelClick(): void {
    this.dialogRef.close(false);
  }

} 
