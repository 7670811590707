import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SignInDialog } from 'src/app/landing/landing.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-allvalue-main',
  templateUrl: './allvalue-main.component.html',
  styleUrls: ['./allvalue-main.component.scss']
})
export class AllvalueMainComponent implements OnInit {


  
  businessunits = [
    {
      id: 4,
      title: 'ALLVALUE',
      bucode : 'AV',
      img: 'assets/av.jpg'
    } ,
    {
      id: 2,
      title: 'ALLDAY SUPERMARKET',
      bucode : 'ADSM',
      img: 'assets/alldaysm.png'
    },
    {
      id: 3,
      title: 'ALLHOME',
      bucode : 'AH',
      img: 'assets/allhome.png'
    },{
      id: 1,
      title: 'COFFEE PROJECT',
      bucode : 'CP',
      img: 'assets/coffeeproject.png',
    },{
      id: 1,
      title: 'DEAR JOE',
      bucode : 'DJ',
      img: 'assets/dj1.jpg',
    },{
      id: 1,
      title: 'DEAR JOE',
      bucode : 'DJ',
      img: 'assets/dj2.jpg',
    },
     
    {
      id: 5,
      title: 'FINDS FINDS',
      bucode : 'FF',
      img: 'assets/findsfindscard.jpg'
    },
    {
      id: 6,
      title: 'ALLTOYS',
      bucode : 'AT',
      img: 'assets/alltoyscard.jpg'
    } 
    ,
    {
      id: 7,
      title: 'ALLSPORTS',
      bucode : 'AS',
      img: 'assets/allsportscard.jpg'
    }



  ];
   constructor(public dialog: MatDialog, private route : Router) {}

  openDialog(): void {
    const dialogRef = this.dialog.open(SignInDialog, {
      width: '450px', 
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => { 
      // this.animal = result;
    });
  }
 
   
  ngOnInit() {
    
  }


  gotoBU(bu: string){

    let token = localStorage.getItem('lrttoken');
    if(token != null && token != '' ){
      // this.route.navigate(['/allvalue/avgmain']); 
      let userbu = localStorage.getItem('storecode');
      let selectedbu = bu;

      if(userbu === selectedbu)
      {
        this.route.navigate(['dashboard/allvalue/sale']);
      }else{
         
      }
    }else{
      this.openDialog();
    }
     
    
  }

}
