import { Component, OnInit } from '@angular/core'; 
import { FormControl, FormGroup } from '@angular/forms';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';

const today = new Date();
const month = today.getMonth();
const year = today.getFullYear();
const day = today.getDay();
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  txnDateFrom = new Date();
  txnDateTo = new Date();

  filterForm = new FormGroup({
    start: new FormControl(new Date(year, month, day)),
    end: new FormControl(new Date(year, month, day)),
  });
  
  constructor(private _bottomSheetRef: MatBottomSheetRef<SearchComponent>) {}


  openLink(event: MouseEvent): void {
    this._bottomSheetRef.dismiss();
    event.preventDefault();
  }

  ngOnInit() {
  }

  dateFromChangeHandler(event : any){ 
    this.txnDateFrom = event;
  }

  dateToChangeHandler(event : any){
    this.txnDateTo = event;
  }
  cancel(){ 
    this._bottomSheetRef.dismiss();
     
  }

  submit(){  
     this.filterForm.setValue({
      start : this.txnDateFrom,
      end : this.txnDateTo
     })
    this._bottomSheetRef.dismiss(this.filterForm.value);
   
   
}

}
