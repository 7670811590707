import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-allvalue-dashboard',
  templateUrl: './allvalue-dashboard.component.html',
  styleUrls: ['./allvalue-dashboard.component.scss']
})
export class AllvalueDashboardComponent implements OnInit {

  appitems: any;;
  // appitems = [
  //   {
  //     label: 'Redemption',
  //     link: 'allvalue/sale',
  //     icon: 'shopping_cart',
  //   },
  //   {
  //     label: 'Activate Card',
  //     link: 'allvalue/dashboard/activate',
  //     icon: 'credit_card',
  //   },
  //   {
  //     label: 'Check Balance',
  //     link: 'allvalue/dashboard/balance',
  //     icon: 'account_balance_wallet'
  //   },
  //   {
  //     label: 'Card Transaction History',
  //     link: 'allvalue/dashboard/cardtxn',
  //     icon: 'list', 
  //   },
  //   {
  //     label: 'My Transactions',
  //     link: 'allvalue/dashboard/reports',
  //     icon: 'list_alt', 
  //   },
  //   // {
  //   //   label: 'Sign Out',
  //   //   link: 'index',
  //   //   icon: 'exit_to_app', 
  //   // }


  // ];


  
  config = {
    paddingAtStart: true,
    interfaceWithRoute: true,
    classname: 'my-custom-class',
    listBackgroundColor: `rgb(0, 126, 169)`,
    fontColor: `rgb(255, 255, 255)`,
    backgroundColor: `rgb(0, 126, 169)`,
    selectedListFontColor: `rgb(255 , 255 , 255)`,
    highlightOnSelect: true,
    collapseOnSelect: true,
    rtlLayout: false
};
  

  constructor(private route: Router) { }

  ngOnInit() {
    if(localStorage.getItem('lrttoken') == '' || localStorage.getItem('lrttoken') == null){
      this.route.navigate(['index']);
    }

    if(localStorage.getItem("utype") == "4"){
      this.appitems = [
        {
          label: 'Redemption',
          link: 'allvalue/sale',
          icon: 'shopping_cart',
        },
        {
          label: 'Check Balance',
          link: 'allvalue/dashboard/balance',
          icon: 'account_balance_wallet'
        },
        {
          label: 'Card Transaction History',
          link: 'allvalue/dashboard/cardtxn',
          icon: 'list', 
        },
        {
          label: 'My Transactions',
          link: 'allvalue/dashboard/reports',
          icon: 'list_alt', 
        },
        // {
        //   label: 'Sign Out',
        //   link: 'index',
        //   icon: 'exit_to_app', 
        // }
    
    
      ];  
    }else if(localStorage.getItem("utype") == "19"){
      this.appitems = [
        {
          label: 'Activate Card',
          link: 'allvalue/dashboard/activate',
          icon: 'credit_card',
        },
        {
          label: 'Check Balance',
          link: 'allvalue/dashboard/balance',
          icon: 'account_balance_wallet'
        },
        {
          label: 'Card Transaction History',
          link: 'allvalue/dashboard/cardtxn',
          icon: 'list', 
        },
        {
          label: 'My Transactions',
          link: 'allvalue/dashboard/reports',
          icon: 'list_alt', 
        },
        // {
        //   label: 'Sign Out',
        //   link: 'index',
        //   icon: 'exit_to_app', 
        // }
    
    
      ];
    }else if(localStorage.getItem("utype") == "18"){
      this.appitems = [
        {
          label: 'Redemption',
          link: 'allvalue/sale',
          icon: 'shopping_cart',
        },
        {
          label: 'Activate Card',
          link: 'allvalue/dashboard/activate',
          icon: 'credit_card',
        },
        {
          label: 'Check Balance',
          link: 'allvalue/dashboard/balance',
          icon: 'account_balance_wallet'
        },
        {
          label: 'Card Transaction History',
          link: 'allvalue/dashboard/cardtxn',
          icon: 'list', 
        },
        {
          label: 'My Transactions',
          link: 'allvalue/dashboard/reports',
          icon: 'list_alt', 
        },
        // {
        //   label: 'Sign Out',
        //   link: 'index',
        //   icon: 'exit_to_app', 
        // }
    
    
      ];
    }

  }
  selectedItem($event){ 
  }
}
