import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatDialog, MatSnackBar, MatPaginator } from '@angular/material';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { WalletLoadingApi } from 'src/app/sharedservices/wallet-api';
import { SignInDialog } from 'src/app/landing/landing.component';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { AllAlertDialogComponent } from '../alert-dialog/allalert-dialog.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { PrintarDialogComponent } from '../printar-dialog/printar-dialog.component';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { SystemUser } from "../../sharedservices/user-interface"; 
import { Guid } from "guid-typescript";
import { DatePipe } from '@angular/common'; 

const today = new Date();
const month = today.getMonth();
const year = today.getFullYear();

@Component({
  selector: 'app-allpay-wallet-main',
  templateUrl: './allpay-wallet-main.component.html',
  styleUrls: ['./allpay-wallet-main.component.scss']
})
export class AllpayWalletMainComponent implements OnInit {

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  
  isValid: boolean = false;
  displayedColumns: string[] = ["datetime","referenceno", "status", "amount"];
  dataSource = new MatTableDataSource();

  public now: Date = new Date();
  walletForm: FormGroup;  
  durationInSeconds = 5; 
  continue: boolean = false;
  guid : Guid;
  sum: number;
  gdata: any;

  txnDate = new Date();
  
  // campaignOne = new FormGroup({
  //   start: new FormControl(new Date(year, month, 13)),
  //   end: new FormControl(new Date(year, month, 16)),
  // });
  // campaignTwo = new FormGroup({
  //   start: new FormControl(new Date(year, month, 15)),
  //   end: new FormControl(new Date(year, month, 19)),
  // });

  constructor( 
    private route : Router,
    public fb: FormBuilder,
    public walletapi: WalletLoadingApi,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private spinner: NgxSpinnerService,
    private datePipe: DatePipe
    
    // private systemuser: SystemUser
    ) { 
      this.guid = Guid.create();

      this.walletForm = fb.group({
        "loadreferencenumber": ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern('[a-zA-Z0-9]+')]],
        "amount": ['0.00', [Validators.required]],
        "subscriber": ['']
      })
 
      setInterval(() => {
        this.now = new Date();
      }, 1);


      
    }

    ngOnInit() {
      this.walletForm.reset();
      this.walletForm.controls['amount'].disable(); 
      // this.walletForm.setValue({loadreferencenumber:"",amount:0})
      this.getLoadTransaction();
      // this.getTotalLoad();
      // if(localStorage.getItem('lrttoken') == '' || localStorage.getItem('lrttoken') == null  ){
      //   this.openDialog();
      // } 

    }

    // openDialog(): void {
    //   const dialogRef = this.dialog.open(SignInDialog, {
    //     width: '450px', 
    //     data: {}
    //   });
  
    //   dialogRef.afterClosed().subscribe(result => {
    //     console.log('The dialog was closed');
    //     this.getLoadTransaction();
    //     // this.animal = result;
    //   });
    // }

  resetForm() : void {
    this.isValid = false;
    this.walletForm.controls['loadreferencenumber'].enable();
    this.walletForm.setValue({
      "loadreferencenumber": "",
      "amount" : "0",
      "subscriber": ""
    })
  }  
  validateRef() {
    this.spinner.show();
    
    if(this.walletForm.controls['loadreferencenumber'].value === undefined || this.walletForm.controls['loadreferencenumber'].value == "")
    {
      this.openAlertDialog('Reference number is required.',"", false);
      this.spinner.hide();
    
      return;
    }
    this.walletapi.validateRef2(this.walletForm.controls['loadreferencenumber'].value).subscribe((data: any) => {
      this.spinner.hide();  
      console.log(data);
      let d1 = data.data.data; 
      this.walletForm.setValue({
        loadreferencenumber: d1.refno,
        amount: d1.amount,
        subscriber: d1.name
      });
      this.isValid = true;
      this.walletForm.controls['loadreferencenumber'].disable();
      let dialogmessage = "Reference number found. "
      this.openAlertDialog(dialogmessage,{ refno: d1.refno, subscriber:  d1.name, amount: d1.amount }, true )
      
    },
      error => {
        console.log(error);
        this.spinner.hide();
        this.isValid = false; 
        if(error.status == 400 || error.error.res1.message){
          this.openAlertDialog(error.error.res1.message,{ refno: "", subscriber:  "", amount: 0.00 }, false);
        }

        if(error.status == 500){
          this.openAlertDialog("Connection problem, please contact support",{ refno: "", subscriber:  "", amount: 0.00 }, false);
        
        }
        this.walletForm.controls['loadreferencenumber'].enable();
        this.resetForm();
      
      })
  }

  getTotalLoad() {
    
    // this.gdata.forEach(d => {
    //   this.sum = this.sum + d.amount;
    //   console.log(this.sum);
    // });
    this.sum = this.gdata.reduce(function (sum, amount) {
      return sum + Number.parseFloat(amount.amount);
    }, 0);
     
    }
  

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
 
  getLoadTransaction() {
    this.spinner.show();
    let params = {
      'userid' : localStorage.getItem('userid'),
      'TxnDateFrom' : this.datePipe.transform(this.txnDate, 'yyyy-MM-dd'),
      'TxnDateTo' : this.datePipe.transform(this.txnDate, 'yyyy-MM-dd')
      
    } 
    this.walletapi.getLoadingTransactions(params).subscribe((data: any) => {
      console.log(data);
      this.gdata = data;
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      // this.spinner.hide();
      this.getTotalLoad();
      
    },
    (err : HttpErrorResponse)=>{ 
      // this.spinner.hide();  
      //  this.route.navigate(['index']);
   }
    )

    this.spinner.hide();

  }

  addLoadTransaction() {
    this.openConfirmDialog(); 
  }

  openConfirmDialog() {
    let waletFormData = this.walletForm.getRawValue();
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '450px',
      data: {
        message: 'Continue with the transaction?',
        details1: waletFormData.loadreferencenumber,
        details2: waletFormData.amount,
        buttonText: {
          cancel: 'No',
          ok: 'Yes'
        }
      }
    });
 

    dialogRef.afterClosed().subscribe((confirmed: boolean) => { 
      if (confirmed) {
        let walletFormData = this.walletForm.getRawValue(); 
        let params = {"referenceno":walletFormData.loadreferencenumber,
                  "amount":walletFormData.amount,
                  "transactiondate":new Date(),
                  "status":"New",
                  "remarks": "New",
                  "userid": Number.parseInt(localStorage.getItem('userid')),
                  "merchantid": Number.parseInt(localStorage.getItem('mid')),
                  "merchantrequestid":  Guid.create().toString(),
                  }
 
        this.spinner.show();
        this.walletapi.addLoadingTransactions(params).subscribe({
          next: this.handleAddLoadingTransactionResponse.bind(this),
          error: this.handleAddLoadingTransactionError.bind(this)
        })
    
      }
    });
  }

  handleAddLoadingTransactionError(err: any){
    this.spinner.hide(); 
    this.openAlertDialog(err.error.message,"", false);
  }
  handleAddLoadingTransactionResponse(res: any){ 
    this.openAlertDialog(res.message,"", false);
    this.spinner.hide();
    this.walletForm.reset();
    this.walletForm.controls['amount'].disable();
    this.getLoadTransaction();
  }

  // saveProcessedLoad(res: any) 
  saveProcessedLoad()
  {
    let walletFormdata = this.walletForm.getRawValue();
    let params = {
      "referenceno": walletFormdata.loadreferencenumber,
      "amount": walletFormdata.amount,
      "transactiondate": this.datePipe.transform(this.txnDate, 'yyyy-MM-dd HH:mm:ss'),
      // old implem
      // "status": "Credited",
      // new implem
      "status": "Pending",
      "userid": localStorage.getItem('userid'),
      "merchantid": localStorage.getItem('mid'),
      // old implem
      //"remarks": res.data.data.message,
      // new implem
      "remarks": "",
      "merchantrequestid":  Guid.create().toString(),

    } 
    this.spinner.show();
    this.walletapi.addLoadingTransactions(params).subscribe((data: any) => { 
      this.spinner.hide();
      this.walletForm.controls['loadreferencenumber'].setValue('');
      this.walletForm.controls['amount'].setValue('0'); 
    })
  }

  openAlertDialog(message: string, details: any, pwithdetails: boolean) {
    let waletFormData = this.walletForm.getRawValue();
    const dialogRef = this.dialog.open(AllAlertDialogComponent, {
      width: '450px',
      data: {
        withdetails: pwithdetails,
        message: message,
        details: details,
        buttonText: {
          ok: 'OK'
        }
      }
    });
    // const snack = this.snackBar.open('Snack bar open before dialog');

    dialogRef.afterClosed().subscribe((confirmed: boolean) => { 
      if (confirmed) { 
      }
    });
  }

  openPrintARDialog() {
    let waletFormData = this.walletForm.getRawValue();
    const dialogRef = this.dialog.open(PrintarDialogComponent, {
      width: '600px',
      height: '400px',
      data: {
        message: 'Transaction Details',
        trxdate: '',
        trxrefno: '',
        loadrefno: waletFormData.amount,
        trxstatus: '',
        store:'',
        buttonText: {
          cancel: 'Cancel',
          ok: 'Print'
        }
      }
    });

 

    dialogRef.afterClosed().subscribe((confirmed: boolean) => { 
      if (confirmed) {
        this.spinner.show();
        this.walletapi.processLoad(waletFormData.loadreferencenumber).subscribe((data: any) => {
          this.spinner.hide();
          this.isValid = false;
          this.openAlertDialog('Transaction was approved.',"", false); 
          this.saveProcessedLoad()

        },
          error => {
            this.spinner.hide();
            this.isValid = false; 
            this.openAlertDialog(error.error.message,"", false);
          })
      }
    });
  }

  donloadReport() {
    this.exportCSV();
  }
  //Download CSV Report
exportCSV() {
  var csvData = this.ConvertToCSV(this.gdata);
  var a = document.createElement("a");
  a.setAttribute('style', 'display:none;');
  document.body.appendChild(a);
  var blob = new Blob([csvData], { type: 'text/csv' });
  var url = window.URL.createObjectURL(blob);
  a.href = url;
  // a.download = 'SalesReport_'+ this.datePipe.transform(this.startDate,"yyMMxdd") + "-" + this.datePipe.transform(this.endDate, "yyMMdd") +'.csv';
  a.download = 'CollectionReport.csv';
  
  a.click();
}

ConvertToCSV(objArray) {
  var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
  var str = '';
  var row = "";

  for (var index in objArray[0]) {
    //Now convert each value to string and comma-separated
    row += index + ',';
  }
  row = row.slice(0, -1);
  //append Label row with line break
  str += row + '\r\n';

  for (var i = 0; i < array.length; i++) {
    var line = '';
    for (var index in array[i]) {
      if (line != '') line += ','

      line += array[i][index];
    }
    str += line + '\r\n';
  }
  return str;
}
}



