import { Component, OnInit, Input } from '@angular/core';
import { SignInDialog } from '../../landing/landing.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-allvalue',
  templateUrl: './allvalue.component.html',
  styleUrls: ['./allvalue.component.scss']
})
export class AllvalueComponent implements OnInit {

  isauthorized: boolean;


  constructor(public dialog: MatDialog,
              private route: Router) { }

  ngOnInit() {
    this.isauthorized =  (localStorage.getItem('authorized') == "true"); 
    this.signInsignOut();
  }

  signInsignOut(){
    if(this.isauthorized){
      this.isauthorized = true;
    }else{
      this.isauthorized = false;
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(SignInDialog, {
      width: '450px', 
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => { 
      // this.animal = result;
    });
  }

  signOut(){
    localStorage.setItem('lrttoken','');
    this.route.navigate(['./index']);
  }

}
