import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class WalletLoadingApi {
    readonly baseUrl = environment.baseUrl;

    private userClaims = new BehaviorSubject<any>({});
    currentUserClaims = this.userClaims.asObservable();

    constructor(private http: HttpClient) { }

    validateRef(parms){
         return this.http.get(this.baseUrl + parms);
    }

    validateRef2(parms){
       return this.http.get(this.baseUrl + 'cashin/' + parms);
       
   }

    processLoad(params){
        return this.http.post("this.loadingapi" + params, null); 
    }

    cashin(params){
        return this.http.post(this.cashin + params, null); 
    }


    getLoadingTransactions(params) {

        console.log(params)
        return this.http.post( 
            this.baseUrl + 'cashin/loadtransactions',
            params,
            { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem('token') }) }
        ); 
    }

    addLoadingTransactions(params: any) {
        return this.http.post(
            this.baseUrl + 'cashin', 
            params,
            { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem('lrttoken') }) }
        );
 
    }
}