import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingComponent } from './landing/landing.component'; 
import { AllvalueComponent } from './landing-dashboard/allvalue/allvalue.component'; 
import { AuthguardGuard } from './authguard.guard'
import { AllvalueMainComponent } from './landing-dashboard/allvalue/allvalue-main/allvalue-main.component';
import { AllvalueDashboardComponent } from './landing-dashboard/allvalue/allvalue-dashboard/allvalue-dashboard.component';
import { AvgSaleComponent } from './landing-dashboard/allvalue/avg-sale/avg-sale.component';
import { AvgActivateComponent } from './landing-dashboard/allvalue/avg-activate/avg-activate.component';
import { AvgCheckbalanceComponent } from './landing-dashboard/allvalue/avg-checkbalance/avg-checkbalance.component';
import { AvgGctransactionComponent } from './landing-dashboard/allvalue/avg-gctransaction/avg-gctransaction.component';
import { AvgTransactionComponent } from './landing-dashboard/allvalue/avg-transaction/avg-transaction.component';
import { AllpayWalletComponent } from './allpay-wallet/allpay-wallet.component';
import { AllpayWalletMainComponent } from './allpay-wallet/allpay-wallet-main/allpay-wallet-main.component';
import { LandingDashboardComponent } from './landing-dashboard/landing-dashboard.component';
import { RequestforrefundComponent } from './landing-dashboard/allvalue/requestforrefund/requestforrefund.component';
import { RptwalletloadingComponent } from './landing-dashboard/allvalue/avg-transaction/rptwalletloading/rptwalletloading.component';
import { RptgiftcardtxnComponent } from './landing-dashboard/allvalue/avg-transaction/rptgiftcardtxn/rptgiftcardtxn.component';
 
 
import { UserProfileComponent } from './user-profile/user-profile.component';
 
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { UsersComponent } from './landing-dashboard/admins/users/users.component';
import { NewusersComponent } from './landing-dashboard/admins/newusers/newusers.component';
import { AdminpageComponent } from './landing-dashboard/admins/adminpage/adminpage.component';
import { BranchesComponent } from './landing-dashboard/admins/merchants/branches/branches.component';
import { BrandsComponent } from './landing-dashboard/admins/merchants/brands/brands.component';
import { CompanyComponent } from './landing-dashboard/admins/merchants/company/company.component';
import { AddmerchantComponent } from './landing-dashboard/admins/merchants/addmerchant/addmerchant.component';
import { AddbranchComponent } from './landing-dashboard/admins/merchants/addbranch/addbranch.component';
import { AddbrandComponent } from './landing-dashboard/admins/merchants/addbrand/addbrand.component';

const routes: Routes = [
  { path: '', redirectTo: 'index', pathMatch: 'full'},
  { path: 'index', component: LandingComponent },
  { path: 'dashboard', component: LandingDashboardComponent, canActivate: [AuthguardGuard], 
    children:[
    { path: 'profile', component: UserProfileComponent},
    { path: 'alleasy', component: AllpayWalletMainComponent}, 
    { path: 'allvalue', component: AllvalueComponent, children: [
        {path: 'avgmain', component: AllvalueMainComponent },
        {path: 'sale', component: AvgSaleComponent},
        {path: 'activate', component: AvgActivateComponent},
        {path: 'balance', component: AvgCheckbalanceComponent},
        {path: 'cardtxn', component: AvgGctransactionComponent},
        {path: 'refund', component: RequestforrefundComponent},
        {path: 'reports', component: AvgTransactionComponent, children: [
          {path: 'walletloading', component: RptwalletloadingComponent},
          {path: 'giftcardtxn', component: RptgiftcardtxnComponent},
        ]},  
    ]},
    { path:  'admins',children: [
      { path: 'users', children: [
        { path: 'merchant-users', component: UsersComponent},
        { path: 'newmerchantusers', component: NewusersComponent}
      ]},
      { path: 'merchant', children: [
        { path: 'company', component: CompanyComponent},
        { path: 'brands', component: BrandsComponent},
        { path: 'branch', component: BranchesComponent},
        { path: 'addmerchant', component: AddmerchantComponent},
        { path: 'addbrand', component: AddbrandComponent},
        { path: 'addbranch', component: AddbranchComponent},
      ]}
    ]}
  ]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
