import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { CardTransactionApi } from 'src/app/sharedservices/card-transaction-api';
import { MatDialogRef, MAT_DIALOG_DATA, MatPaginator, MatTableDataSource, MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { WalletLoadingApi } from 'src/app/sharedservices/wallet-api';
import { NgxSpinnerService } from 'ngx-spinner';
import { AllAlertDialogComponent } from 'src/app/allpay-wallet/alert-dialog/allalert-dialog.component';
import { HttpErrorResponse } from '@angular/common/http';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';

@Component({
  selector: 'app-avg-gctransaction',
  templateUrl: './avg-gctransaction.component.html',
  styleUrls: ['./avg-gctransaction.component.scss']
})
export class AvgGctransactionComponent implements OnInit {

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  
  isValid: boolean = false;
  
  displayedColumns: string[] = ["trxdate","referenceno", "trxtype", "trxamount", "respmessage", "detailsid", "controlno", "cashier", "branch"];
  dataSource = new MatTableDataSource();
  txnlist : any;
  transactionForm: FormGroup;   

  constructor( public cardsapi: CardTransactionApi,
                private route : Router,
                public fb: FormBuilder,
                public walletapi: WalletLoadingApi,
                public dialog: MatDialog, 
                private spinner: NgxSpinnerService) { 
    
      this.transactionForm = new FormGroup({
        'cardnumber' : new FormControl() 
      })
  }


  ngOnInit() { 
  }

  getTransactions() :void {
    this.spinner.show();
    let txnForm = this.transactionForm.value;
    console.log(txnForm);
    this.cardsapi.cardTransaction({"cardnumber": txnForm.cardnumber}).subscribe((data:any)=>{ 
      console.log(data);
      this.txnlist = data;
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.spinner.hide();
      if(data.length > 0){
        this.openAlertDialog( data.length + ' transaction(s) found. Click OK to continue');
      }else{
        this.openAlertDialog( "No record(s) found. Click OK to continue");
      
      }
      
    },
    (err : HttpErrorResponse)=>{
      console.log(err);
      this.spinner.hide();  
       this.route.navigate(['index']);
       
      
   });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getTotalCost() {
    return this.txnlist.map(t => t.amount).reduce((acc, value) => acc + value, 0);
  }
  openAlertDialog(message: string) {
    // let waletFormData = this.walletForm.getRawValue();
    const dialogRef = this.dialog.open(AllAlertDialogComponent, {
      width: '450px',
      data: {
        details:{
          refno:""
        },
        message: message,
        buttonText: {
          ok: 'OK'
        }
      }
    });
    // const snack = this.snackBar.open('Snack bar open before dialog');

    dialogRef.afterClosed().subscribe((confirmed: boolean) => { 
      if (confirmed) {
        this.transactionForm.setValue({
          "cardnumber":""
        }) 
      }
      this.spinner.hide();
    });
  }

  copyRefNo(inputElement){
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
 
  }

  onKeydown(event) {
    if (event.key === "Enter") { 
      this.getTransactions();
    }
  }
}


