import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-allpay-wallet-dashboard',
  templateUrl: './allpay-wallet-dashboard.component.html',
  styleUrls: ['./allpay-wallet-dashboard.component.scss']
})
export class AllpayWalletDashboardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
