import { Component, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef, MatPaginator, MatTableDataSource } from '@angular/material'; 
import { HttpsvcService } from 'src/app/sharedservices/httpsvc.service';
import { AddmerchantComponent } from '../addmerchant/addmerchant.component';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit {

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

  merchants: any = [];
  showLoader: boolean = false;
  displayedColumns: string[] = ['merchantid', 'merchantcode', 'businessname', 'merchantisactive'];
  dataSource = new MatTableDataSource<any>();
  
  length = 100;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 25, 100];

  constructor(private httpsvc: HttpsvcService, 
    private bottomSheet: MatBottomSheet ) { }

  ngOnInit(): void {
    this.getMerchants();
  }

  getMerchants(){
    this.httpsvc.getData('api/merchant/mastermerchants').subscribe({
      next: this.handleGetData.bind(this)
    })
  }
  
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  handleGetData(res: any){ 
    this.merchants = res;
    this.dataSource = new MatTableDataSource(this.merchants); 
    this.length = this.merchants.length;
    this.dataSource.paginator = this.paginator;

  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    //console.log(this.dataSource.filter);
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  addMerchant(){
    this.bottomSheet.open(AddmerchantComponent).afterDismissed().subscribe((res:any)=>{ 
      if(res){
        this.getMerchants();
      }
    });
  
  }
 

}
