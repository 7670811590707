import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import { FormGroup, FormControl, FormBuilder, Validators } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { WalletLoadingApi } from '../sharedservices/wallet-api';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SignInDialog } from '../landing/landing.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';

import { MatSnackBar } from '@angular/material/snack-bar'; 



 

@Component({
  selector: "app-allpay-wallet",
  templateUrl: "./allpay-wallet.component.html",
  styleUrls: ["./allpay-wallet.component.scss"]
})
export class AllpayWalletComponent implements OnInit {

   

  constructor( ) { }

  ngOnInit() {}
    

}

 
