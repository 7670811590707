import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatBottomSheetRef, MatDialog } from '@angular/material';
import { AlertDialogComponent } from 'src/app/shared-component/alert-dialog/alert-dialog.component';
import { HttpsvcService } from 'src/app/sharedservices/httpsvc.service';

@Component({
  selector: 'app-addbrand',
  templateUrl: './addbrand.component.html',
  styleUrls: ['./addbrand.component.scss']
})
export class AddbrandComponent implements OnInit {

  companies: any = [];
  userid: number = 0;
  frmAddBrand: FormGroup;
  selectedcompany: any;
  constructor(private fb: FormBuilder,
    private httpsvc: HttpsvcService,
    private dialog: MatDialog,
    private bottomSheetRef: MatBottomSheetRef<AddbrandComponent>) {

    this.frmAddBrand = fb.group({
      createuserid: [0, Validators.required],
      mastermerchantid: [0, Validators.required],
      merchanttype: ['', Validators.required],
      merchantwebsite: ['na', Validators.required],
      businessname: ['', Validators.required],
      merchantcode: ['', Validators.required],
    })
  }

  ngOnInit() {
    this.getMasterMerchants();
  }

  submit() {

    const dialogRef = this.dialog.open(AlertDialogComponent, {
      data: {
        title: "New Merchant",
        message: "Do you want to proceed with this request?"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.userid = Number.parseInt(localStorage.getItem("userid") || '0');
        this.frmAddBrand.controls['merchanttype'].setValue('2');
        this.frmAddBrand.controls['mastermerchantid'].setValue(Number.parseInt(this.selectedcompany));
        this.frmAddBrand.controls['createuserid'].setValue(this.userid); 
        this.httpsvc.postData("api/merchant/add", this.frmAddBrand.value).subscribe({
          next: this.handleAddCompanyResponse.bind(this),
          error: this.handleAddCompanyError.bind(this)
        })
      }


    });


  }

  handleAddCompanyResponse(res: any) { 
    if (res.status) {
      const dialogRef = this.dialog.open(AlertDialogComponent, {
        data: {
          title: "New Merchant",
          message: res.message
        }
      });

      dialogRef.afterClosed().subscribe(result => { 

        if (result) {
          this.bottomSheetRef.dismiss(result);
        }


      });
    }
  }
  handleAddCompanyError(err: any) { 
  }

  cancel() { 
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      data: {
        title: "New Merchant",
        message: "Do you want to cancel this request?"
      }
    });

    dialogRef.afterClosed().subscribe(result => { 

      if (result) {
        this.bottomSheetRef.dismiss();
      }


    });

  }
  getMasterMerchants() {
    this.httpsvc.getData('api/merchant/mastermerchants').subscribe({
      next: this.handleGetMasterMerchantsData.bind(this),
      error: this.handleError.bind(this)
    })
  }

  handleError(res: any) {

  }
  handleGetMasterMerchantsData(res: any) { 
    this.companies = res;
  }

  onChange() {
    this.frmAddBrand.controls["mastermerchantid"].setValue(this.selectedcompany)
    console.log(this.frmAddBrand.value);
  }


}
