import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpsvcService {

  readonly apihost = environment.baseUrl;

  constructor(private http: HttpClient) { }


  postData(ep: string, params: any) {
    return this.http.post(
        this.apihost + ep,
        params,
        { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem('lrttoken') }) }
    ); 
  }

  patchData(ep: string, params: any) {
    return this.http.patch(
        this.apihost + ep,
        params,
        { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem('lrttoken') }) }
    ); 
  }

  getData(ep: string){
    return this.http.get(
      this.apihost + ep,
      { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem('lrttoken') }) }
  ); 
  }


}
