import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatBottomSheetRef, MatDialog, MatPaginator, MatTableDataSource } from '@angular/material';
import { AlertDialogComponent } from 'src/app/shared-component/alert-dialog/alert-dialog.component';
import { HttpsvcService } from 'src/app/sharedservices/httpsvc.service';

@Component({
  selector: 'app-newusers',
  templateUrl: './newusers.component.html',
  styleUrls: ['./newusers.component.scss']
})
export class NewusersComponent implements OnInit {

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  dataSource = new MatTableDataSource();
  displayedColumns: string[] = ["id","fname", "lname", "username","usertype", "status" ];
  companies: any = []
  selectedcompany: string = "";

  brands: any = []
  selectedbrand: string = "";

  branches: any = []
  selectedbranch: string = "";

  users: any = []

  roles: any = []
  selectedrole: any
  
  
  frmAddUser!: FormGroup;

  constructor(private httpsvc: HttpsvcService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private bottomSheetRef: MatBottomSheetRef<NewusersComponent>) {

      this.frmAddUser = fb.group({
        fname: ['', Validators.required],
        lname: ['', Validators.required],
        email: ['', Validators.required],
        mobilenumber: ['', Validators.required],
        phonenumber: ['', Validators.required],
        usertype: ['', Validators.required],
        merchantid: ['', Validators.required],
        storecode: ['', Validators.required],
        username: ['', Validators.required],
        password: ['', Validators.required],
        createuserid:[]
      });
     }

  ngOnInit() {
    this.getMasterMerchants();
    this.getUsertypes();
  }

  getUsertypes(){ 
    let utype = "merchant";
    if(localStorage.getItem('usertypename').toLowerCase() == 'administrator')
     {
      utype = "all"
     }


    this.httpsvc.getData('api/usertype/' + utype).subscribe((res:any)=>{
      next: this.handleGetUserTypes(res)
      error: this.handleGetUserTypeError(res) 
    });
  }

  handleGetUserTypes(res:any){
    this.roles = res.list    
  }

  handleGetUserTypeError(err: any){
    //console.log(err);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  
  getMasterMerchants(){
     this.httpsvc.getData('api/merchant/mastermerchants').subscribe({
      next: this.handleGetMasterMerchantResponse.bind(this),
      error: this.handleGetMasterMerchantError.bind(this)
     })
  }

  handleGetMasterMerchantResponse(res: any){ 
    this.companies = res;
    

  }
  handleGetMasterMerchantError(err: any){ 
    
  }

  
  getBrands(){ 
    this.httpsvc.getData('api/merchant/brands/' + this.selectedcompany).subscribe({
      next: this.handleGetBrandsResponse.bind(this),
      error: this.handleGetBrandsError.bind(this)
    })
  }

  handleGetBrandsResponse(res: any){
    this.brands = res;
  }
  handleGetBrandsError(err: any){ 
  }


  getBranches(){ 
    this.httpsvc.getData('api/merchant/branches/' + this.selectedbrand).subscribe({
      next: this.handleGetBranchesResponse.bind(this),
      error: this.handleGetBranchesError.bind(this)
    })
  }

  handleGetBranchesResponse(res: any){
    this.branches = res;
  }
  handleGetBranchesError(err: any){ 
  }

  getUsers(){ 
    this.httpsvc.getData('api/merchant/merchantusers/' + this.selectedbranch).subscribe({
      next: this.handleGetUsersResponse.bind(this),
      error: this.handleGetUsersError.bind(this)
    })
  }

  handleGetUsersResponse(res: any){
    this.users = res;
    this.dataSource = new MatTableDataSource<any>(this.users);
    this.dataSource.paginator = this.paginator;
  }
  handleGetUsersError(err: any){ 
  }

  cancel(){
    // this.dialog.open(AlertDialogComponent);
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      data : {
        title: "New User",
        message: "Do you want to cancel this request?"
      }
    });

    dialogRef.afterClosed().subscribe(result => { 

      if(result){
        this.bottomSheetRef.dismiss();
      }


    });
    
  }

  submit(){
     
    this.frmAddUser.setValue({
        "fname": this.frmAddUser.controls["fname"].value,
        "lname": this.frmAddUser.controls["lname"].value,
        "email": this.frmAddUser.controls["email"].value,
        "mobilenumber": this.frmAddUser.controls["mobilenumber"].value,
        "phonenumber": this.frmAddUser.controls["phonenumber"].value,
        "usertype": Number.parseInt(this.selectedrole),
        "merchantid": Number.parseInt(this.selectedcompany),
        "storecode": this.selectedbranch,
        "username": this.frmAddUser.controls["username"].value,
        "password": this.frmAddUser.controls["password"].value,
        "createuserid": Number.parseInt(localStorage.getItem("userid").toString())
    })



 
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      data : {
        title: "New User",
        message: "Do you want to save this request?"
      }
    });

    dialogRef.afterClosed().subscribe(result => { 
      if(result){

        this.httpsvc.postData('api/user/adduser',this.frmAddUser.value).subscribe({
          next: this.handleAddUserResponse.bind(this),
          error: this.handleAddUserError.bind(this)
        })

         
      }


    });
  }


  handleAddUserResponse(res: any){ 
    if(res.status == "success"){ 
      const dialogRef = this.dialog.open(AlertDialogComponent, {
        data : {
          title: "New User",
          message: "New user created successfully."
        }
      });
  
      dialogRef.afterClosed().subscribe(result => { 
        if(result){ 
          this.bottomSheetRef.dismiss();
        }
   
      });
    }
  }

  handleAddUserError(err: any){ 
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      data : {
        title: "New User",
        message: "Failed to create user. " + err.error.message 
      }
    });

    // dialogRef.afterClosed().subscribe(result => { 
    //   if(result){ 
    //     this.bottomSheetRef.dismiss();
    //   }
 
    // });
  }

}
