import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Guid } from 'guid-typescript';
// import { MatTableDataSource, MatDialog, MatSnackBar, MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { WalletLoadingApi } from 'src/app/sharedservices/wallet-api';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { CinemaCardsApi } from 'src/app/sharedservices/cinema-cardsapi-service';
import { CardTransactionApi } from 'src/app/sharedservices/card-transaction-api';
import { SearchComponent } from '../../search/search.component';
import {MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-rptgiftcardtxn',
  templateUrl: './rptgiftcardtxn.component.html',
  styleUrls: ['./rptgiftcardtxn.component.scss'],
  
})
export class RptgiftcardtxnComponent implements OnInit {

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  gdata: any;
  isValid: boolean = false;
  displayedColumns: string[] = ["trxdate","referenceno", "trxtype", "controlno", "trxamount" , "respmessage", "detailsid"];
  dataSource = new MatTableDataSource();

  public now: Date = new Date();
  walletForm: FormGroup;  
  durationInSeconds = 5; 
  continue: boolean = false;
  guid : Guid;
  sum: number;
  txnDate = new Date();
  txdate:any;
  txnDateFrom = new Date();
  txnDateTo = new Date();
  found : boolean = false;

  constructor(private route : Router,
    private bottomSheet: MatBottomSheet,
    public fb: FormBuilder,
    public gcapi: CardTransactionApi,
    public dialog: MatDialog, 
    private spinner: NgxSpinnerService,
    private datePipe: DatePipe) { }

  ngOnInit() {
    this.getGcTransactions()
  }

  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getGcTransactions() {
    this.found = false;
    this.dataSource = new MatTableDataSource();
      
    this.spinner.show();
    let params = {
      'userid' : localStorage.getItem('userid'),
      'trxdateto' :  this.datePipe.transform(this.txnDateTo, 'yyyy-MM-dd 23:59:59'),
      'trxdatefrom' :  this.datePipe.transform(this.txnDateFrom, 'yyyy-MM-dd 00:00:00'),
      'storecode' : localStorage.getItem("storecode"),
      'deviceid' : localStorage.getItem("deviceid")
    } 
    this.gcapi.getUserTransactions(params).subscribe((data: any) => { 
      this.gdata = data;
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.spinner.hide();
      this.found = true;
      // this.getTotalLoad();
      
    },
    (err : HttpErrorResponse)=>{ 
      this.spinner.hide();  
       this.route.navigate(['index']);
      }
    )

    
  
  }




  exportCSV() {
    var csvData = this.ConvertToCSV(this.gdata);
    var a = document.createElement("a");
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    var blob = new Blob([csvData], { type: 'text/csv' });
    var url = window.URL.createObjectURL(blob);
    a.href = url;
    // a.download = 'SalesReport_'+ this.datePipe.transform(this.startDate,"yyMMxdd") + "-" + this.datePipe.transform(this.endDate, "yyMMdd") +'.csv';
    a.download = 'CollectionReport.csv';
    
    a.click();
  }
  
  ConvertToCSV(objArray) {
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var str = '';
    var row = "";
  
    for (var index in objArray[0]) {
      //Now convert each value to string and comma-separated
      row += index + ',';
    }
    row = row.slice(0, -1);
    //append Label row with line break
    str += row + '\r\n';
  
    for (var i = 0; i < array.length; i++) {
      var line = '';
      for (var index in array[i]) {
        if (line != '') line += ','
  
        line += array[i][index];
      }
      str += line + '\r\n';
    }
    return str;
  }


  dateFromChangeHandler(event : any){
    this.txnDateFrom = event;
  }

  dateToChangeHandler(event : any){
    this.txnDateTo = event;
  }

  openBottomSheet(): void {
    const bottomSheetRef = this.bottomSheet.open(SearchComponent);
    bottomSheetRef.afterDismissed().subscribe((data: any) => { 
  
      this.txnDateTo =  data.end
      this.txnDateFrom = data.start
      // let params = {
      //   'userid': Number.parseInt(localStorage.getItem('userid')!),
      //   'txndatefrom': this.datePipe.transform(data.start, 'yyyy-MM-dd 00:00:00'),
      //   'txndateto': this.datePipe.transform(data.end, 'yyyy-MM-dd 23:59:59')
  
      // }
      this.getGcTransactions()
    });
  }
}
