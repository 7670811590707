import { Component, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheet, MatPaginator, MatTableDataSource } from '@angular/material';
import { HttpsvcService } from 'src/app/sharedservices/httpsvc.service';
import { AddbranchComponent } from '../addbranch/addbranch.component';
import {MatSort, Sort} from '@angular/material/sort';

@Component({
  selector: 'app-branches',
  templateUrl: './branches.component.html',
  styleUrls: ['./branches.component.scss']
})
export class BranchesComponent implements OnInit {

  brands: any = []
  branch: any = []
  companies: any = [];
  showLoader: boolean = false;
  displayedColumns: string[] = ['merchantid', 'merchantcode', 'businessname', 'merchantisactive', 'action'];
  dataSource = new MatTableDataSource<any>();
  length = 100;
  pageSize = 10;
  selectedcompany: number = 0;
  selectedbrand: number = 0;
  pageSizeOptions: number[] = [10, 25, 100];
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild('merchantSort' , { static: false }) merchantSort = new MatSort();

  constructor(private httpsvc: HttpsvcService,
    private bottomSheet: MatBottomSheet) { }

  ngOnInit(): void {
    this.getMerchants();
  }

  getMerchants() {
    this.httpsvc.getData('api/merchant/mastermerchants').subscribe({
      next: this.handleGetData.bind(this)
    })
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.merchantSort.disableClear = true;
    this.dataSource.sort = this.merchantSort;
  }

  handleGetData(res: any) { 
    this.companies = res;
    // this.dataSource = new MatTableDataSource(this.merchants); 
    // this.length = this.merchants.length;
    // this.dataSource.paginator = this.paginator;

  }

  getBrands() {
    this.httpsvc.getData('api/merchant/brands/' + this.selectedcompany).subscribe({
      next: this.handleGetBrandsData.bind(this),
      error: this.handleError.bind(this)
    })
  }

  handleGetBrandsData(res: any) {
    this.brands = res;

  }

  handleError(res: any) {
     
  }

  getBranch() {
    this.httpsvc.getData('api/merchant/branches/' + this.selectedbrand).subscribe({
      next: this.handleGetBranchData.bind(this),
      error: this.handleError.bind(this)
    })
  }

  handleGetBranchData(res: any) {
    //console.log(res.data);
    if (res.length > 0) {
      this.branch = res;

    } else {
      this.branch = [];

    }
    this.dataSource = new MatTableDataSource(this.branch);
    this.dataSource.sort = this.merchantSort;
    this.length = this.branch.length;
    this.dataSource.paginator = this.paginator;

  }

  addBranch(){
    this.bottomSheet.open(AddbranchComponent).afterDismissed().subscribe((res:any)=>{ 
      if(res){
        this.getBranch();
      }
    });
  }

  setStatus(merchantid: any, status: any) {
    let params = {
      merchantisactive: status,
      merchantid: merchantid
    }
    this.httpsvc.patchData('api/merchant/status', params).subscribe({
      next: this.handleSetStatus.bind(this),
      error: this.handleSetStatusError.bind(this)
    })
  }

  handleSetStatus(res: any){ 
    this.getBranch();
  }

  handleSetStatusError(err: any){ 
    this.getBranch();
  }
   
}
