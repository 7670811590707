import { createViewChild } from '@angular/compiler/src/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheet, MatPaginator, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { HttpsvcService } from 'src/app/sharedservices/httpsvc.service';
import { AddbrandComponent } from '../addbrand/addbrand.component';

@Component({
  selector: 'app-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.scss']
})
export class BrandsComponent implements OnInit {

  companies: any = [];
  brands: any = [];
  selectedcompany: number = 0;
  showLoader: boolean = false;
  displayedColumns: string[] = [ 'merchantcode', 'businessname', 'merchantisactive'];
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

  filtertoggle: boolean = true;
  credstoggle: boolean = true;

  length = 100;
  pageSize = 5;
  pageSizeOptions: number[] = [5, 10, 25, 100];

  constructor(
    private httpsvc: HttpsvcService,  
    private router: Router,  
    private bottomSheet: MatBottomSheet) { }

  ngOnInit(): void {
    this.getMasterMerchants();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
  getMasterMerchants(){
    this.httpsvc.getData('api/merchant/mastermerchants').subscribe({
      next : this.handleGetMasterMerchantsData.bind(this),
      error: this.handleError.bind(this)
    })
  }

  handleError(res: any){
    
  }
  handleGetMasterMerchantsData(res: any){ 
    this.companies = res; 
  }

 

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    //console.log(this.dataSource.filter);
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  toggleFilter(){
    if(this.filtertoggle){
      this.filtertoggle = false;
    }else{
      this.filtertoggle = true;
    }
  }

  toggleCreds(){
    if(this.credstoggle){
      this.credstoggle = false;
    }else{
      this.credstoggle = true;
    }
  }

  closecard(){
    this.filtertoggle = false;
  }

  getBrands(){
    this.httpsvc.getData('api/merchant/brands/' + this.selectedcompany).subscribe({
      next: this.handleGetBrandsData.bind(this),
      error: this.handleError.bind(this)
    })
  }

  handleGetBrandsData(res: any){
    //console.log(res);
    this.brands = res;
    this.dataSource = new MatTableDataSource(this.brands); 
    this.length = this.brands.length;
    this.dataSource.paginator = this.paginator;

  }

 

  viewdetails(row: any){ 
    //console.log(row)
    this.router.navigateByUrl('/main/merchants/brandprofile/' + row.merchantid);
  }

  addBrand(){
    this.bottomSheet.open(AddbrandComponent).afterDismissed().subscribe((res:any)=>{ 
      if(res){
        this.getBrands();
      }
    });
  }

}
